import '@rei/cedar/dist/cdr-fonts.css';
import '@rei/cedar/dist/cdr-reset.css';
import '../../../style/common/landing-site-base.scss';

import '@rei/cedar/dist/style/cdr-button.css';
import '@rei/cedar/dist/style/cdr-accordion-group.css';
import '@rei/cedar/dist/style/cdr-accordion.css';
import '@rei/cedar/dist/style/cdr-img.css';
import '@rei/cedar/dist/style/cdr-grid.css';
import '@rei/cedar/dist/style/cdr-modal.css';
import '@rei/cedar/dist/style/cdr-rating.css';

import '@rei/banner-component/dist/style.css';
import '@rei/ambient-video-player/dist/style.css';
import '@rei/landing-content-block/dist/style.css';
import '@rei/landing-story/dist/style.css';
import '@rei/story-group/dist/style.css';
import '@rei/frequently-asked-questions/dist/style.css';
import '@rei/youtube-video-player/dist/style.css';
import '@rei/landing-mini-banner/dist/style.css';
import '@rei/simple-top-categories/dist/style.css';
import '@rei/filmstrip-carousel/dist/style.css';
import '@rei/landing-badge/dist/style.css';
import '@rei/landing-seo-block/dist/style.css';

import createApp from './main';
import getClientPageData from '../../utils/clientPageData';

const { pageData: props } = getClientPageData();

const { app } = createApp(props);
app.mount('#app');
