<script>
import convertToHtml from '@rei/rich-text-renderer';
import { defineAsyncComponent } from 'vue';
import SimpleTopCategories from '@rei/simple-top-categories';
import { StoriesComponent } from '@rei/landing-story';
import SeoBlock from '@rei/landing-seo-block';
import AsyncProductRecommendations from '../../common/async-components/product-recommendations/AsyncProductRecommendations.vue';
import LeadComponent from '../../common/components/lead/LeadComponent.vue';
import AmbientVideoInBanner from '../../common/components/ambient-video-in-banner/AmbientVideoInBanner.vue';
import SecondaryHeading from '../../common/components/secondary-heading/SecondaryHeading.vue';
import FeaturedProducts from '../h/category-hub/components/featured-products/FeaturedProducts.vue';
import BrandBanner from '../../common/components/brand-banner/BrandBanner.vue';
import getRecs from '../../utils/recommendations';

import analytics from '../../common/mixins/analytics';
import fetchData from '../../utils/api';
import {
  transformLinkCollectionToCategories,
  transformPromotionToContentBlock,
  formatBannerContentStack,
  formatAmbientVideo,
  transformPromotionToLandingStories,
  formatImageComponent,
  configDestructuring,
} from '../../utils/formatContentStackData';
import { getBrandMustache } from '../../utils/formatText';

export default {
  name: 'EnhancedBrandPage',
  components: {
    LeadComponent,
    AmbientVideoInBanner,
    YoutubeVideoModal: defineAsyncComponent(() => import('../../common/components/youtube-video-modal/YoutubeVideoModal.vue')),
    SimpleTopCategories,
    SecondaryHeading,
    FeaturedProducts,
    StoriesComponent,
    SeoBlock,
    BrandBanner,
    AsyncProductRecommendations,
  },
  mixins: [analytics],
  props: {
    additionalData: { type: Object, required: true, default: () => {} },
    componentModels: { type: Object, required: true, default: () => {} },
    richRelevanceEnabled: { type: Boolean, default: true },
  },
  data() {
    return {
      componentData: this.componentModels,
      youtubeVideoModalProps: {
        modalTitle: 'Watch the video',
        modalDescription: 'Watch the video',
        videoId: '',
        showTitle: true,
      },
      isYoutubeVideoModalOpen: false,
      recsPlacement: [],
      recsPlacementConfig: [{ placementType: 'brand_page.core_ol_rec_1' }],
    };
  },
  computed: {
    renderingMap() {
      return {
        lead: !!this.lead,
        'featured-categories': !!this.featuredCategories,
        ambientVideoBanner: !!this.ambientVideoBanner,
        'featured-products': !!this.featuredProducts,
        stories: !!this.stories,
        'brand-banner': !!this.brandBanner,
        recommendations: this.richRelevanceEnabled && !!this.recsPlacement[0],
        'seo-block': !!this.seoBlock,
      };
    },
    uid() {
      const { uid } = this.componentData || {};
      const renderComponent = !!uid;
      return (renderComponent && uid) || null;
    },
    getDynamicText() {
      const { brandName, totalProducts } = this.additionalData || {};
      return getBrandMustache({ totalProducts, brandName });
    },
    lead() {
      const { lead } = this.componentData || {};

      // Copy lead so content can be adjusted to add dynamic text
      const localLead = JSON.parse(JSON.stringify(lead)) || {};

      // // If default button exists, grab dynamic text from it before processing content block.
      if (lead?.content?.actions?.defaultActions[0]) {
        const dynamicText = this.getDynamicText(lead.content.actions.defaultActions[0].text);
        localLead.content.links = lead?.content?.actions?.defaultActions;
        localLead.content.links[0].title = dynamicText;
      }

      // Format lead content to look like a promotion
      // because otherwise we cant use the transformContentBlock util.
      // We need normalized data so we dont have to normalize this on the FE.
      if (lead?.content) {
        localLead.content.copy = {
          description: lead.content.body,
          heading: lead.content.heading,
          eyebrow: lead.content.eyebrow,
          subheading: lead.content.subheading,
        };
      }

      const contentBlockOpts = {
        contentAlign: {
          xs: 'center',
        },
        palette: {
          content: 'inverse',
        },
      };
      const headingOpts = {
        level: 'h1',
      };
      const content = transformPromotionToContentBlock(
        localLead.content,
        contentBlockOpts,
        headingOpts,
      );
      const media = formatImageComponent(localLead?.media, {});
      const bannerComponentProps = {
        contentPosition: {
          xs: 'center bottom',
        },
      };
      const renderComponent = !!lead;
      return (renderComponent && {
        bannerComponentProps,
        media,
        content,
      }) || null;
    },
    featuredCategories() {
      const { featuredCategories } = this.componentData || {};
      const {
        id,
        categories,
        cta: featuredCategoriesCta,
        heading,
      } = featuredCategories || {};
      const { title: text, href: target } = featuredCategoriesCta || {};
      const topCategories = transformLinkCollectionToCategories({ links: categories }, 'categories');
      const cta = { text, target };
      const renderComponent = !!topCategories.length;
      return (
        (renderComponent && {
          id: id || 'shop',
          heading,
          props: {
            grid: '5',
            cta,
            categories: topCategories,
            hasBackground: true,
          },
        })
          || null
      );
    },
    ambientVideoBanner() {
      const { ambientVideoBanner } = this.componentData || {};
      if (!ambientVideoBanner) return null;

      const headingOpts = { level: 'h2', size: 'md-standard' };
      const config = { contentAlign: { xs: 'center' } };

      if (ambientVideoBanner?.promotion.video?.videoId) {
        const javascriptCta = {
          title: 'Watch video',
          href: 'javascript',
        };
        ambientVideoBanner?.promotion?.links?.push(javascriptCta);
      }

      const actionsOpt = { allSecondaryButtons: configDestructuring(ambientVideoBanner, 'secondaryButtonsOnly') };
      const configContent = {
        ...config,
        palette: {
          content: configDestructuring(ambientVideoBanner, 'palette')?.content,
        },
      };
      const content = transformPromotionToContentBlock(
        ambientVideoBanner?.promotion,
        configContent,
        headingOpts,
        actionsOpt
      );
      const media = formatImageComponent(ambientVideoBanner?.promotion?.image);
      const videoId = ambientVideoBanner?.promotion.video?.videoId;
      const video = {
        src: formatAmbientVideo(ambientVideoBanner?.promotion.video?.ambientVideo),
        poster: media?.xs,
        ratio: '56-73',
        cover: true,
      };

      const defaultbannerComponentProps = {
        contentPosition: { xs: 'center bottom', sm: 'center bottom' },
        bannerHeight: 'thin',
        effect: 'Gradient: to top',
      };
      const bannerComponentProps = formatBannerContentStack(
        {
          ...ambientVideoBanner?.config,
          imageAnchor: ambientVideoBanner?.promotion?.imageAnchor,
          effect: configDestructuring(ambientVideoBanner, 'effect'),
          height: configDestructuring(ambientVideoBanner, 'height'),
        },
        {
          ...defaultbannerComponentProps,
        }
      );
      return {
        video,
        content,
        videoId,
        media,
        bannerComponentProps,
      };
    },
    featuredProducts() {
      const { featuredProducts } = this.componentData || {};
      const localFeaturedProducts = JSON.parse(JSON.stringify(featuredProducts)) || {};
      const products = localFeaturedProducts?.products?.map((p) => ({
        props: {
          brand: p.brand || '',
          href: p.canonical || '',
          price: p.price || {},
          title: p.title || '',
          media: p.media || {},
          badge: {
            accentColor: p.badge?.color || '',
            text: p.badge?.title || '',
          },
          rating: p.reviews || {},
          imgSize: 280,
          showPercentOff: true,
        },
      }));
      const renderComponent = !!featuredProducts;
      return renderComponent && {
        products,
        slide: 6,
        heading: localFeaturedProducts?.title,
      };
    },
    stories() {
      const { stories } = this.componentData || {};
      if (!stories) return null;

      const { promotions = [], heading } = stories || {};
      const mapPromotions = promotions.map((promotionSection) => ({
        ...promotionSection,
        actionsOptions: {
          allSecondaryButtons: configDestructuring(stories, 'secondaryButtonsOnly'),
        },
      }));

      const headingSectionOptions = { level: 'h2', size: 'sm-standard' };
      const headingPromotionOptions = { level: 'h3', size: 'sm-standard' };
      const landingStoryOptions = {
        grid: 'single',
        aspectRatio: '5/4',
        storyStackPosition: 'switch',
      };
      const contentBlockOptions = { theme: configDestructuring(stories, 'palette')?.content };

      const formattedStories = transformPromotionToLandingStories(
        mapPromotions,
        landingStoryOptions,
        heading,
        headingSectionOptions,
        contentBlockOptions,
        headingPromotionOptions,
        null
      );
      return { ...formattedStories };
    },
    brandBanner() {
      const { brandBanner } = this.componentData || {};
      const renderComponent = !!brandBanner;
      return renderComponent && {
        id: brandBanner?.id || 'brand-banner',
        heading: brandBanner.content?.heading?.text,
        body: brandBanner.content?.body,
        media: formatImageComponent(brandBanner?.media, {}),
        useDeprecatedImage: false,
      };
    },
    seoBlock() {
      const { seoBlock } = this.componentData || {};
      const { image, heading, body } = seoBlock || {};
      const id = seoBlock?.id || 'seo-block';
      const media = formatImageComponent(image);
      const formattedHeading = heading ? `<h2>${heading}</h2>` : '';
      const text = formattedHeading + convertToHtml(body);
      const renderComponent = !!seoBlock;
      return (renderComponent && {
        id,
        props: {
          media,
          markup: {
            text,
          },
        },
      }) || null;
    },
  },
  async mounted() {
    window.addEventListener('OnLivePreviewUpdate', (event) => {
      const hash = event?.detail?.hash;
      if (hash) {
        fetchData(`/home/rs/enhanced-brand/live-preview?entryId=${this.uid}&hash=${hash}`, this.updateModels);
      }
    });

    if (this.richRelevanceEnabled) {
      const { brandName } = this.additionalData || {};
      this.recsPlacement = await getRecs(this.recsPlacementConfig, 'brand', brandName);
    }
  },
  methods: {
    updateModels(data) {
      this.componentData = data;
    },
    closeModal() {
      this.isYoutubeVideoModalOpen = false;
      this.youtubeVideoModalProps.videoId = '';
    },
    openYoutubeVideoModal(videoId) {
      this.youtubeVideoModalProps.videoId = videoId;
      this.isYoutubeVideoModalOpen = true;
    },
  },
};
</script>
<template>
  <div
    class="brand"
    data-ui="brand"
  >
    <!-- Lead -->
    <section
      v-if="renderingMap.lead"
      :data-location-name="analyticsMap.lead"
      data-ui="brand-lead"
    >
      <div class="brand-lead-component">
        <lead-component v-bind="lead" />
      </div>
    </section>

    <!-- Top categories -->
    <section
      v-if="renderingMap['featured-categories']"
      :id="featuredCategories.id"
      :data-location-name="analyticsMap['featured-categories']"
      class="brand__container brand__container--narrow brand__featured-categories"
      data-ui="brand-top-categories"
    >
      <secondary-heading :text="featuredCategories.heading" />
      <simple-top-categories v-bind="featuredCategories.props" />
    </section>

    <!-- Ambient video in banner -->
    <section
      v-if="renderingMap['ambientVideoBanner']"
      :data-location-name="analyticsMap['ambientVideoBanner']"
      data-ui="brand-ambient-video"
      class="brand__container brand__container--narrow brand__ambient-video"
    >
      <ambient-video-in-banner
        v-bind="ambientVideoBanner"
        @open-youtube-video-modal="openYoutubeVideoModal(ambientVideoBanner?.videoId)"
      />
    </section>
    <!-- Modals -->
    <youtube-video-modal
      :opened="isYoutubeVideoModalOpen"
      v-bind="youtubeVideoModalProps"
      @onModalClose="closeModal"
    />

    <!-- Featured Products -->
    <section
      v-if="renderingMap['featured-products']"
      :id="featuredCategories.id"
      :data-location-name="analyticsMap['featured-products']"
      class="brand__container brand__container--narrow"
      data-ui="brand-featured-products"
    >
      <div class="featured-products-container">
        <div class="featured-products__heading">
          <secondary-heading :text="featuredProducts.heading" />
        </div>
        <featured-products
          :products="featuredProducts.products"
          :slides-visible="2"
        />
      </div>
    </section>

    <!-- featured-stories -->
    <section
      v-if="renderingMap['stories']"
      :data-location-name="analyticsMap['stories']"
      data-ui="brand-stories"
      class="brand__container brand__container--narrow"
    >
      <stories-component v-bind="stories" />
    </section>

    <!-- Brand Banner -->
    <section
      v-if="renderingMap['brand-banner']"
      :id="brandBanner.id"
      :data-location-name="analyticsMap['brand-banner']"
      class="brand__brand-banner brand__container brand__container--narrow "
      data-ui="brand-brand-banner"
    >
      <brand-banner v-bind="brandBanner" />
    </section>

    <div
      v-if="renderingMap['recommendations']"
      class="brand__container brand__container--narrow"
      data-ui="recommendation-slider"
    >
      <async-product-recommendations
        :placement="recsPlacement[0]"
        :metrics="metrics"
      />
    </div>

    <!-- Seo block -->
    <section
      v-if="renderingMap['seo-block']"
      :data-location-name="analyticsMap['seo-block']"
      data-ui="brand-seo-block"
      class="brand__container brand__container--narrow"
    >
      <seo-block
        v-bind="seoBlock.props"
      />
    </section>
  </div>
</template>
<style lang="scss">
@import "@rei/cdr-tokens/dist/rei-dot-com/scss/cdr-tokens.scss";
@import '@rei/cdr-component-variables/dist/scss/index.scss';
.brand {
  &__container {
    padding-top: $cdr-space-one-and-a-half-x;
    padding-bottom: $cdr-space-one-and-a-half-x;

    @include cdr-sm-mq-up {
      padding-top: $cdr-space-three-x;
      padding-bottom: $cdr-space-three-x;
    }

    &--narrow {
      @include cdr-container;
    }
  }

  .brand-lead-component {
    .banner__content-block {
      background: linear-gradient(to top, #000000 -3.78%, rgba(0, 0, 0, 0) 87.39%);
    }
    .ls-content-block {
      max-width: 736px;
    }
  }

  &__ambient-video {
    @include cdr-sm-mq-down {
      padding: 0;
    }
  }
}
</style>
