<script>
import FilmstripCarousel from '@rei/filmstrip-carousel';

export default {
  name: 'FeaturedProducts',
  components: {
    FilmstripCarousel,
  },
  props: {
    products: { type: Array, required: false, default: () => ([]) },
    slidesVisible: { type: Number, required: true, default: 2 },
  },
};
</script>
<template>
  <filmstrip-carousel
    class="featured-products"
    :slides="products"
    :slides-visible="slidesVisible"
    :slide-style="`Product 1:1`"
  />
</template>
<style lang="scss">
@import '@rei/cdr-tokens/dist/rei-dot-com/scss/cdr-tokens.scss';

// Component variables
$viewport: 100vw;
$container: $cdr-space-one-x;
$cdrContainer: calc((#{$viewport} - 123.2rem) / 2 + #{$cdr-space-two-x});
$gutter: $cdr-space-one-and-a-half-x;
$peek: $cdr-space-two-x;
$xsDimension: calc(#{$viewport} - #{$container} - #{$gutter} - #{$peek});
$smDimension: calc((#{$viewport} - #{$container} - (#{$gutter} * 3) - #{$peek}) / 2);
$mdDimension: calc((#{$viewport} - #{$container} - (#{$gutter} * 4) - #{$peek}) / 3);
$lgDimension: calc((#{$viewport} - #{$cdrContainer} * 2 - #{$gutter} * 2) / 3);

.featured-products {
  &__slide {
    display: flex;
    flex-wrap: wrap;
    flex: 0;
    list-style: none;
    padding-right: $cdr-space-half-x;
    position: relative;

    @include cdr-md-mq-up {
      padding-right: $cdr-space-one-and-a-half-x;
    }
  }

  &__inner {
    border-radius: $cdr-radius-softer;
    overflow: hidden;
    position: relative;
    margin-bottom: $cdr-space-half-x;
    width: $xsDimension;

    @include cdr-sm-mq-up {
      width: $smDimension;
    }

    @include cdr-md-mq-up {
      width: $mdDimension;
    }

    @include cdr-lg-mq-up {
      width: $lgDimension;
    }
  }
}
</style>
