<script>
import { CdrText, CdrButton } from '@rei/cedar';
import StoryGroup from '@rei/story-group';
import LandingImageComponent from '@rei/landing-image-component';
import LandingImageComponentDeprecated from '@rei/landing-image-component-deprecated';
import supportDeprecatedImageComponent from '../../mixins/supportDeprecatedImageComponent';

export default {
  name: 'BrandBanner',
  components: {
    CdrText,
    CdrButton,
    StoryGroup,
    LandingImageComponent,
    LandingImageComponentDeprecated,
  },
  mixins: [supportDeprecatedImageComponent],
  props: {
    media: { type: Object, required: true, default: () => {} },
    heading: { type: String, required: false, default: '' },
    body: { type: String, required: false, default: '' },
    cta: { type: Object, required: false, default: () => {} },
  },
  computed: {
    renderCta() {
      return this.cta && this.cta?.target && this.cta.text;
    },
    renderContent() {
      return this.heading || this.body || this.cta;
    },
  },
};
</script>

<template>
  <story-group class="banner-card">
    <template #column-1>
      <div
        data-ui="brand-banner-img"
        class="banner-card__img-container"
      >
        <component
          :is="imageComponent"
          :media="media"
          :lazy-load="false"
          v-bind="media"
          fetch-priority="high"
          class="banner-card__picture"
          custom-classes="banner-card__img"
        />
      </div>
    </template>
    <template
      v-if="renderContent"
      #column-2
    >
      <div
        data-ui="brand-banner-content"
        class="banner-card__content"
      >
        <h2
          v-if="heading"
          data-ui="brand-banner-heading"
          class="banner-card__heading"
          v-html="heading"
        />
        <cdr-text
          v-if="body"
          data-ui="brand-banner-body"
          class="banner-card__body"
          v-html="body"
        />
        <cdr-button
          v-if="renderCta"
          :href="cta.target"
          :modifier="cta.modifier || 'primary'"
          data-ui="brand-banner-cta"
          tag="a"
          class="banner-card__cta"
        >
          {{ cta.text }}
        </cdr-button>
      </div>
    </template>
  </story-group>
</template>
<style lang="scss">
@import "@rei/cdr-tokens/dist/rei-dot-com/scss/cdr-tokens.scss";

.banner-card {
  border-radius: 0.8rem;
  background-color: $cdr-color-background-secondary;
  padding: $cdr-space-two-x;

  &__heading {
    @include cdr-text-heading-serif-600;
    margin: 0 0 $cdr-space-three-quarter-x;
  }

  &__img-container {
    margin: 0 auto $cdr-space-three-quarter-x;

    picture {
      display: block;
      max-width: 250px;
      margin: 0 auto;
      width: 100%;
    }

    @include cdr-sm-mq-up {
      margin-bottom: 0;
      width: 100%;
      position: relative;
      display: flex;
      height: 100%;
      align-items: center;

      &:before {
        content: "";
        display: block;
        padding-top: 62.7%;
      }

      picture {
        max-width: 350px;
      }
    }
  }

  &__content {
    text-align: center;

    @include cdr-sm-mq-up {
      padding-left: $cdr-space-inset-four-x-squish-left-right;
      padding-right: $cdr-space-inset-three-x-stretch-left-right;
      text-align: left;
    }
  }

  &__body {
    @include cdr-text-body-300;
  }

  &__cta {
    margin-top: $cdr-space-one-and-a-half-x;
  }
}
</style>
